import aktuImage from 'assets/images/aktu.png'
import bibaImage from 'assets/images/biba.png'
import zuzuImage from 'assets/images/zuzu.png'

import './LoggedOut.css';
import classes from './LoggedOut.module.scss';

const coins = [
    {
        name: 'MUMU',
        img: aktuImage,
        change: '+19.2%',
    },
    {
        name: 'BODEN',
        img: zuzuImage,
        change: '+18.1%',
    },
    {
        name: 'GME',
        img: bibaImage,
        change: '+15.0%',
    },
];

export default function LoggedOut() {
    return (
            <div className="app-logged-out">
                <header className="header">
                    <h1><a href='/'>Memegains</a></h1>
                    <a href="/silver" className={classes.Btn}>Connect MetaMask / Phantom Wallet</a>
                </header>
                <main>
                    <h2>Coins of the day + Backtest stats</h2>
                    <div className="coins">
                        {coins.map((coin, index) => (
                            <div className="coin" key={index}>
                                <img src={coin.img} alt={coin.name} />
                                <h3>{coin.name}</h3>
                                <p className="change">{coin.change}</p>
                            </div>
                        ))}
                    </div>
                </main>
            </div>
    );
}
