import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import bibaImage from 'assets/images/biba.png';

import './Silver.css';
import classes from './Silver.module.scss';


const cx = classNames.bind(classes);

const coins = [
    {
        name: 'SOLANA - BODEN',
        img: bibaImage,
        change: '+14%',
    },
];

export default function Silver() {
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => setIsSuccess(false), 2_000);
        }
    }, [isSuccess]);

    return (
        <div className="app-silver">
            <header className="header">
                <h1><a href='/'>Memegains</a></h1>
                <button
                    className={cx({ payBtn: true, clickable: !isSuccess })}
                    onClick={() => !isSuccess && setIsSuccess(true)}
                >
                    {isSuccess ? 'Success' : 'Add $USDT 100+ to gain 5% / month'}
                </button>
                <p className="deposit-fee">Deposit fee - Y%</p>
            </header>
            <main>
                <h2>Gainer of the day</h2>
                <div className="coins">
                    {coins.map((coin, index) => (
                        <div className="coin" key={index}>
                            <img src={coin.img} alt={coin.name} />
                            <h3>{coin.name}</h3>
                            <p className="change">{coin.change}</p>
                        </div>
                    ))}
                </div>
            </main>
            <div className={classes.cohortBox}>
                <div className={classes.cohortTop}>
                    Balance: $200
                </div>
                <a href='/gold'>
                    <div className={classes.cohortBottom}>
                        Cohort: Silver
                    </div>
                </a>
            </div>
        </div>
    );
}
