import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Gold from 'pages/Gold/Gold';
import LoggedOut from 'pages/LoggedOut/LoggedOut';
import Silver from 'pages/Silver/Silver';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path={'/'} element={<LoggedOut />} />
        <Route path={'/gold'} element={<Gold />} />
        <Route path={'/silver'} element={<Silver />} />
        <Route path={'*'} element={<Navigate to={''} />}/>
      </Routes>
    </BrowserRouter>
  );
}
